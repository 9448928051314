// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const FORUM_POST_ADMINCLUB_MENU = "Adminclub";
export const PREGNANCY_NEWSLETTER = "Tehotensk\u00fd newsletter";
export const PREGNANCY_NEWSLETTER_DESCRIPTION = "Ka\u017ed\u00fd t\u00fd\u017ede\u0148 dostane\u0161 najd\u00f4le\u017eitej\u0161ie info do e-mailu";
export const RELATED_DISCUSSIONS_IN_FORUM = "S\u00favisiace diskusie vo f\u00f3re";
export const SEO_TITLE_WIKI_article = "%(article)s";
export const WIKI_ARTICLE_ADD_NEW = "Pridaj nov\u00fd \u010dl\u00e1nok";
export const WIKI_ARTICLE_AMBASSADOR_BRAND_CLEAR = "Odpoj ambas\u00e1dorsk\u00fa zna\u010dku";
export const WIKI_ARTICLE_AMBASSADOR_BRAND_SET = "Pripoj ambas\u00e1dorsk\u00fa zna\u010dku";
export const WIKI_ARTICLE_EDIT = "Uprav";
export const WIKI_REPORT_MISTAKE_IN_ARTICLE = "Nahl\u00e1s chybu v \u010dl\u00e1nku";
export const WIKI_WRITE_EXPERIENCE = "Nap\u00ed\u0161 sk\u00fasenos\u0165";
