import { faComment, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { photoUrl } from 'mk/photo/photoUrl';
import { blogsProfileUrl } from 'mk2/apps/blogs/urls';
import { AvatarImg } from 'mk2/components/AvatarImg';
import { Clamp } from 'mk2/components/Clamp';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { url } from 'mk2/helpers/urls';
import { PhotoEntity, UserEntity } from 'mk2/schemas';
import React from 'react';
import styles from './ReviewBlogPostPreview.mscss';

interface OwnProps {
    author?: UserEntity;
    badges?: React.ReactNode[];
    className?: string;
    photo: PhotoEntity;
    postUrl: string;
    title: string;
    commentsCount?: number;
    likersCount?: number;
    urlDataStoreClick?: string;
}

type Props = OwnProps;

const bestVersion = (photo: PhotoEntity) => {
    if (photo.versions.includes('snx100')) {
        return 'snx100';
    }
    if (photo.versions.includes('s150x150')) {
        return 's150x150';
    }
    if (photo.versions.includes('s560x560')) {
        return 's560x560';
    }

    return 's1600x1600';
};

export default class ReviewBlogPostPreview extends React.Component<Props> {
    public render() {
        const { className, author, badges, photo, title, postUrl, commentsCount, likersCount, urlDataStoreClick } = this.props;
        return (
            <div className={cx(styles.ReviewBlogPostPreview, className)}>
                <div className={styles.ReviewBlogPostPreview__content}>
                    <div className={styles.ReviewBlogPostPreview__image}>
                        <Link to={postUrl}>
                            {photo
                                ? <ImgCropped
                                    className={styles.ReviewBlogPostPreview__image__img}
                                    width={80}
                                    height={80}
                                    mode={ImgCropMode.Cover}
                                    imgUrl={photoUrl(photo, bestVersion(photo))}
                                    imgWidth={photo.width}
                                    imgHeight={photo.height}
                                    lazy
                                />
                                : <div className={styles.ReviewBlogPostPreview__image__placeholder}/>
                            }
                        </Link>
                    </div>
                    <div className={styles.ReviewBlogPostPreview__rightColumn}>
                        {badges?.length > 0 && (
                            <div className={styles.ReviewBlogPostPreview__badgesAndStats}>
                                <div className={styles.ReviewBlogPostPreview__badges}>
                                    {badges.map((badge, i) => (
                                        <span key={i} className={styles.ReviewBlogPostPreview__badges__badge}>{badge}</span>
                                    ))}
                                </div>
                                <div className={styles.ReviewBlogPostPreview__stats}>
                                    {likersCount ? (
                                        <span className={styles.ReviewBlogPostPreview__stats__stat}>
                                            {likersCount} <FontAwesomeIcon icon={faThumbsUp} />
                                        </span>
                                    ) : null}
                                    {commentsCount ? (
                                        <span className={styles.ReviewBlogPostPreview__stats__stat}>
                                            {commentsCount} <FontAwesomeIcon icon={faComment} />
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        )}
                        <Link to={postUrl}>
                            <h3
                                className={cx(
                                    styles.ReviewBlogPostPreview__title,
                                    styles.ReviewBlogPostPreview__title__hover,
                                )}>
                                <Clamp clamp={4}>
                                    <div>{title}</div>
                                </Clamp>
                            </h3>
                        </Link>
                        {author && (
                            <Link
                                to={url(blogsProfileUrl, {username: author.username})}
                                className={styles.ReviewBlogPostPreview__user}
                            >
                                <AvatarImg
                                    size={24}
                                    user={author}
                                    className={styles.ReviewBlogPostPreview__user__avatar}
                                />
                                {author.username}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}


interface PlaceholderOwnProps {
    author: boolean;
    badges: boolean;
    className?: string;
}

export const ReviewBlogPostPreviewPlaceholder = React.memo<PlaceholderOwnProps>(({
    className,
    author,
    badges,
}) => {
    return (
        <div className={cx(styles.ReviewBlogPostPreview, className)}>
            <div className={styles.ReviewBlogPostPreview__content}>
                <div className={styles.ReviewBlogPostPreview__image}>
                    <LoadingPlaceholder
                        width={80}
                        height={80}
                        className={styles.ReviewBlogPostPreview__image__img}
                    />
                </div>
                <div className={styles.ReviewBlogPostPreview__rightColumn}>
                    {badges && (
                        <div className={styles.ReviewBlogPostPreview__badges}>
                            <LoadingPlaceholder
                                width={150}
                                height={14}
                                className={styles.ReviewBlogPostPreview__badges__badge}
                            />
                        </div>
                    )}
                    <h3 className={styles.ReviewBlogPostPreview__title}>
                        <LoadingPlaceholder
                            width={'30%'}
                            height={22}
                            className={styles.ReviewBlogPostPreview__inlinePlaceholder}
                        />
                        <span className={styles.ReviewBlogPostPreview__inlinePlaceholder}>&nbsp;</span>
                        <LoadingPlaceholder
                            width={'15%'}
                            height={22}
                            className={styles.ReviewBlogPostPreview__inlinePlaceholder}
                        />
                        <span className={styles.ReviewBlogPostPreview__inlinePlaceholder}>&nbsp;</span>
                        <LoadingPlaceholder
                            width={'25%'}
                            height={22}
                            className={styles.ReviewBlogPostPreview__inlinePlaceholder}
                        />
                    </h3>
                    {author && (
                        <div className={styles.ReviewBlogPostPreview__user}>
                            <LoadingPlaceholder
                                width={24}
                                height={24}
                                className={cx(
                                    styles.ReviewBlogPostPreview__inlinePlaceholder,
                                    styles.ReviewBlogPostPreview__user__avatar,
                                )}
                            />
                            <LoadingPlaceholder
                                width={150}
                                height={14}
                                className={styles.ReviewBlogPostPreview__inlinePlaceholder}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

ReviewBlogPostPreviewPlaceholder.displayName = 'ReviewBlogPostPreviewPlaceholder';
