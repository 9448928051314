import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import {
    SEO_TITLE_REVIEW_PAGE_COMPONENT_REVIEWS,
    SEO_TITLE_REVIEW_PAGE_COMPONENT_WHERE_TO_BUY,
    WIKI_WRITE_EXPERIENCE,
} from 'mk/autogenerated/translations/ReviewArticlePage.9229e107ff9563947d81f3e2aff9bc27'
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { loginUrl } from 'mk2/apps/users/urls';
import { VendorReplyOnExperienceFormData } from 'mk2/apps/wiki/components/VendorReplyOnExperienceForm';
import {
    articleDeleteVendorReplyOnExperienceTrigger,
    articleLoadMoreReviewCategoryArticlesTrigger,
    articleSaveVendorReplyOnExperienceTrigger,
} from 'mk2/apps/wiki/containers/Article/Article.actions';
import { ReviewCategoriesStats } from 'mk2/apps/wiki/containers/Article/Article.reducers';
import { getWikiArticleState } from 'mk2/apps/wiki/containers/Article/Article.selectors';
import ArticlePage, {
    DispatchProps as ArticleDispatchProps,
    OwnProps,
    RouteProps,
    StateProps,
} from 'mk2/apps/wiki/containers/Article/ArticlePage';
import ReviewArticle from 'mk2/apps/wiki/containers/Article/ReviewArticle';
import { isArticleOfReviewsCategory, isReviewHomepageArticle, WIKI_REVIEWS_ARTICLE_SLUG } from 'mk2/apps/wiki/helpers';
import { articleWiki, conceptsBreadcrumbsWiki, reviewArticleWiki } from 'mk2/apps/wiki/structuredData';
import { getWikiArticleUrl, wikiAddExperienceToUndefinedArticleUrl, wikiAddExperienceUrl } from 'mk2/apps/wiki/urls';
import { Btn, BtnType } from 'mk2/components/Btn';
import { TrackClick } from 'mk2/components/TrackClick';
import { getAllDescendantsOfTypes } from 'mk2/helpers/article_ast/utils';
import { hasVal } from 'mk2/helpers/sanitize';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { url } from 'mk2/helpers/urls';
import { BrandingProps, HalfpageProps } from 'mk2/pages/Page';
import { AppState } from 'mk2/reducers';
import {
    ExperienceEntity,
    ExperienceSchema,
    ReviewCategoryEntity,
    ReviewCategorySchema,
    WikiArticleEntity,
    WikiArticleSchema,
} from 'mk2/schemas';
import { getDenormalizedEntities } from 'mk2/selectors';
import React from 'react';
import { MapStateToPropsParam } from 'react-redux';

interface DispatchProps extends ArticleDispatchProps {
    onDeleteVendorReply(experienceId: number);
    onLoadMoreReviewCategoryArticles(articleSlug: string, categorySlug: string, after: number);
    onSaveVendorReply(experienceId: number, values: VendorReplyOnExperienceFormData);
}

export type Props = OwnProps & StateProps & DispatchProps & RouteProps;

export default class ReviewArticlePage extends ArticlePage {
    public render(props: Props): false | JSX.Element {
        return <ReviewArticle {...props} />;
    }

    public getBrandingProps(props: Props): BrandingProps {
        return null;
    }

    public getHalfpageProps(props: Props): HalfpageProps {
        return null;
    }

    public hasHeaderBackButton(props: Props): boolean {
        return !isReviewHomepageArticle(props.categorySlug, props.articleSlug);
    }

    public getDefaultBackPath(props: Props): string {
        return getWikiArticleUrl(WIKI_REVIEWS_ARTICLE_SLUG, WIKI_REVIEWS_ARTICLE_SLUG);
    }

    public getStructuredData(props: Props): object[] {
        const { article, articleConceptPath, baseUrl, reviewCategoryArticlesCount } = props;
        if (article) {
            const isProductReview =
                isArticleOfReviewsCategory(article.category.slug) &&
                (reviewCategoryArticlesCount === null || reviewCategoryArticlesCount === undefined) &&
                !isReviewHomepageArticle(article.category.slug, article.slug);
            return [
                conceptsBreadcrumbsWiki(baseUrl, articleConceptPath),
                !isProductReview ? articleWiki(baseUrl, article) : reviewArticleWiki(baseUrl, article),
            ];
        }
    }

    public getAddExperienceBtn(props: Props): React.ReactElement {
        const { article, requestUser, reviewCategoryArticlesCount } = props;
        let addBtn;
        if (article.experiencesAllowed) {
            const addExpUrl = url(wikiAddExperienceUrl, {
                articleSlug: article.slug,
                categorySlug: article.category.slug,
            });
            addBtn = (
                <TrackClick
                    name="wiki_experience_add_clicked"
                    key="addBtn"
                    props={{
                        article_id: article.id,
                        category_slug: article.category.slug,
                        article_slug: article.slug,
                    }}
                >
                    <Btn
                        key="addBtn"
                        type={BtnType.Blue}
                        icon={faPencil}
                        label={WIKI_WRITE_EXPERIENCE}
                        link={isAuthenticated(requestUser) ? addExpUrl : url(loginUrl, {}, { next: addExpUrl })}
                    />
                </TrackClick>
            );
        }

        const isReviewCategory = hasVal(reviewCategoryArticlesCount);
        const isReviewHP = isReviewHomepageArticle(article.category.slug, article.slug);
        const isReviewHomepageOrCategory = isReviewHP || isReviewCategory;
        if (isReviewHomepageOrCategory) {
            const addExpUrl = url(wikiAddExperienceToUndefinedArticleUrl, {});
            addBtn = (
                <TrackClick
                    name="wiki_experience_add_clicked"
                    key="addBtn"
                    props={{
                        article_id: article.id,
                        category_slug: article.category.slug,
                        article_slug: article.slug,
                    }}
                >
                    <Btn
                        key="addBtn"
                        type={BtnType.Blue}
                        icon={faPencil}
                        label={WIKI_WRITE_EXPERIENCE}
                        link={isAuthenticated(requestUser) ? addExpUrl : url(loginUrl, {}, { next: addExpUrl })}
                    />
                </TrackClick>
            );
        }
        return addBtn;
    }

    public mapStateToProps(): MapStateToPropsParam<StateProps, OwnProps & RouteProps, AppState> {
        return (state: AppState, ownProps: OwnProps & RouteProps): StateProps => {
            const articleSlug = ownProps.match.params.articleSlug;
            const categorySlug = ownProps.match.params.categorySlug;
            const articleState = getWikiArticleState(state, categorySlug, articleSlug);

            const reviewCategoryArticles: WikiArticleEntity[] = getDenormalizedEntities<WikiArticleEntity>(
                state,
                WikiArticleSchema,
                articleState.reviewCategoryArticlesIds || [],
            );
            const reviewCategoryArticlesCount = articleState.reviewCategoryArticlesCount;
            const reviewCategoryExperiences: ExperienceEntity[] = getDenormalizedEntities<ExperienceEntity>(
                state,
                ExperienceSchema,
                articleState.reviewCategoryArticlesExperiencesIds || [],
            );
            const reviewCategories: ReviewCategoryEntity[] = getDenormalizedEntities<ReviewCategoryEntity>(
                state,
                ReviewCategorySchema,
                articleState.reviewCategoriesIds || [],
            );
            const reviewCategoriesStats: ReviewCategoriesStats = articleState.reviewCategoriesStats;

            return {
                ...(super.mapStateToProps()(state, ownProps) as StateProps),
                reviewCategoryArticles,
                reviewCategoryArticlesCount,
                reviewCategoryExperiences,
                reviewCategories,
                reviewCategoriesStats,
            };
        };
    }

    public mapDispatchToProps(): MapDispatchToPropsObject<DispatchProps> {
        return {
            ...super.mapDispatchToProps(),
            onDeleteVendorReply: articleDeleteVendorReplyOnExperienceTrigger,
            onLoadMoreReviewCategoryArticles: articleLoadMoreReviewCategoryArticlesTrigger,
            onSaveVendorReply: articleSaveVendorReplyOnExperienceTrigger,
        };
    }

    public getMetaTitle(props: Props): string {
        const { article } = props;
        const parent = super.getMetaTitle(props);
        if (article && parent) {
            const parts = [];
            if (article.experiences?.length) {
                parts.push(SEO_TITLE_REVIEW_PAGE_COMPONENT_REVIEWS);
            }
            const whereToBuy = getAllDescendantsOfTypes(article.bodyAST, [
                'product_box',
                'reviews',
                'previews',
                'pricemania',
                'market',
                'eshop',
            ]);
            if (whereToBuy?.length) {
                parts.push(SEO_TITLE_REVIEW_PAGE_COMPONENT_WHERE_TO_BUY);
            }
            if (parts.length) {
                return `${parent} - ${parts.join(', ')}`;
            } else {
                return parent;
            }
        } else {
            return parent;
        }
    }
}
