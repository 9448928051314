import uniq from 'lodash-es/uniq';
import {
    ReviewHomepageRecentBlogArticlesLoadFailureAction,
    ReviewHomepageRecentBlogArticlesLoadRequestAction,
    ReviewHomepageRecentBlogArticlesLoadSuccessAction,
    REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_FAILURE,
    REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_REQUEST,
    REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_SUCCESS,
} from 'mk2/apps/wiki/containers/ReviewHomepageRecentBlogArticles/ReviewHomepageRecentBlogArticles.actions';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';

type ReviewHomepageRecentBlogArticlesLoadActions =
    ReviewHomepageRecentBlogArticlesLoadRequestAction
    | ReviewHomepageRecentBlogArticlesLoadSuccessAction
    | ReviewHomepageRecentBlogArticlesLoadFailureAction;

export interface PerArticleSlugState {
    blogPostArticleIds: number[];
    hasMore: boolean;
    errorMessage: string;
    loadingState: LoadingState;
}

export const perArticleSlugInitialState: PerArticleSlugState = {
    blogPostArticleIds: [],
    hasMore: false,
    errorMessage: null,
    loadingState: LoadingState.INIT,
};

const perArticleSlugReducer: Reducer<PerArticleSlugState, ReviewHomepageRecentBlogArticlesLoadActions> = (state = perArticleSlugInitialState, action) => {
    switch (action.type) {
        case REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_REQUEST:
        case REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_FAILURE:
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
            };
        case REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_SUCCESS:
            return {
                ...state,
                blogPostArticleIds: uniq([...state.blogPostArticleIds, ...action.response.result.blogPostArticles]),
                hasMore: action.hasMore,
                errorMessage: errorMessage(state.errorMessage, action),
                loadingState: loadingState(state.loadingState, action),
            };
        default:
            return state;
    }
};

export interface State {
    [articleSlug: string]: PerArticleSlugState;
}

export const initialState: State = {};

const reducer: Reducer<State, ReviewHomepageRecentBlogArticlesLoadActions> = (state = initialState, action) => {
    switch (action.type) {
        case REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_REQUEST:
        case REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_FAILURE:
        case REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_SUCCESS:
            const key = action.articleSlug;
            return {
                ...state,
                [key]: perArticleSlugReducer(state[key], action),
            };
        default:
            return state;
    }
};

export default reducer;
