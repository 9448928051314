// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_BEST = "Zoradi\u0165 od najlep\u0161\u00edch";
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_LIKES = "Naju\u017eito\u010dnej\u0161ie hodnotenia";
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_NEWEST = "Najnov\u0161ie hodnotenia";
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_PHOTOS = "Hodnotenia s fotkami";
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_WORST = "Zoradi\u0165 od najhor\u0161\u00edch";
export const WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_ANY_REVIEWABLE = "M\u00e1\u0161 sk\u00fasenos\u0165 s produktom, slu\u017ebou alebo miestom?";
export const WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_title = "M\u00e1\u0161 sk\u00fasenos\u0165 %(title)s?";
export const WIKI_EXP_AVERAGE_SCORE = "Priemern\u00e9 hodnotenie:";
export const WIKI_EXPERIENCES_WITH_REVIEWS__HP = "Najnov\u0161ie sk\u00fasenosti";
export const WIKI_EXPERIENCES_WITH_title = "Hodnotenia a sk\u00fasenosti %(title)s";
export const WIKI_REVIEW_CATEGORY__NEW_ARTICLES_OF_category = "Nov\u00e9 v kateg\u00f3rii %(category)s";
export const WIKI_SHARE_YOUR_EXPERIENCE_AND_HELP_OTHERS = "Pode\u013e sa o \u0148u a pom\u00f4\u017e tak ostatn\u00fdm mami\u010dk\u00e1m.";
export const WIKI_WHERE_NEXT = "Kam \u010falej?";
export const WIKI_WRITE_YOUR_EXPERIENCE = "Nap\u00ed\u0161 svoju sk\u00fasenos\u0165";
