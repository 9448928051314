import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import useClickOutside from 'mk2/hooks/useClickOutside';
import useEventCallback from 'mk2/hooks/useEventCallback';
import React, { useRef, useState, PropsWithChildren } from 'react';
import styles from './SimpleSelect.mscss';

export interface OwnProps {
    className?: string;
    selected: string;
    options: {
        [key: string]: React.ReactNode;
    };
    type: 'filter' | 'sort' | 'bigger';
    onChange(optionKey: string);
}

type Props = OwnProps;

const SimpleSelect: React.FunctionComponent<Props> = ({ className, options, selected, type, onChange }) => {
    const containerRef = useRef<HTMLDivElement>();
    const [opened, setOpened] = useState<boolean>(false);

    const onToggleCallback = useEventCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            setOpened((s) => !s);
        },
        [setOpened],
    );

    // Click outside
    const onCloseCallback = useEventCallback(() => {
        if (opened) {
            setOpened(false);
        }
    }, [opened, setOpened]);
    useClickOutside(containerRef, onCloseCallback);

    return (
        <div
            ref={containerRef}
            className={cx(
                styles.SimpleSelect,
                styles[`SimpleSelect--${type}`],
                opened && styles['SimpleSelect--opened'],
                className,
            )}
            onClick={onToggleCallback}
        >
            <div className={cx(styles.SimpleSelect__toggle, styles[`SimpleSelect__toggle--${type}`])}>
                <span className={styles.SimpleSelect__toggle__label}>{options[selected]}</span>
                <span className={styles.SimpleSelect__toggle__caret}>
                    <FontAwesomeIcon icon={faCaretDown} />
                </span>
            </div>
            {opened && (
                <ul className={cx(styles.SimpleSelect__dropdown, styles[`SimpleSelect__dropdown--${type}`])}>
                    {Object.keys(options).map((optionKey) => (
                        <SimpleSelectOption
                            key={optionKey}
                            optionKey={optionKey}
                            onSelect={onChange}
                            selected={selected === optionKey}
                        >
                            {options[optionKey]}
                        </SimpleSelectOption>
                    ))}
                </ul>
            )}
        </div>
    );
};

SimpleSelect.displayName = 'SimpleSelect';

export default React.memo<Props>(SimpleSelect);

interface SimpleSelectOptionOwnProps {
    selected: boolean;
    optionKey: string;
    onSelect(optionKey: string);
}

let SimpleSelectOption: React.FunctionComponent<SimpleSelectOptionOwnProps> = ({
    selected,
    optionKey,
    children,
    onSelect,
}) => {
    const onSelectCallback = useEventCallback(
        (event: React.MouseEvent<HTMLLIElement>) => {
            onSelect(optionKey);
        },
        [onSelect, optionKey],
    );

    return (
        <li
            className={cx(styles.SimpleSelectOption, selected && styles['SimpleSelectOption--selected'])}
            onClick={onSelectCallback}
        >
            {children}
        </li>
    );
};

SimpleSelectOption.displayName = 'SimpleSelectOption';

SimpleSelectOption = React.memo<PropsWithChildren<SimpleSelectOptionOwnProps>>(SimpleSelectOption);
