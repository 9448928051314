import { wiki_api_v2_load_recent_blog_articles_url } from 'mk/urls/functions';
import {
    reviewHomepageRecentBlogArticlesLoadApi,
    ReviewHomepageRecentBlogArticlesLoadNormalizedResponse,
    ReviewHomepageRecentBlogArticlesLoadTriggerAction,
    REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_TRIGGER,
} from 'mk2/apps/wiki/containers/ReviewHomepageRecentBlogArticles/ReviewHomepageRecentBlogArticles.actions';
import { normalizeError, XHRAction } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { all, call, put, takeEvery } from 'redux-saga/effects';

const logger = getLogger('wiki/ReviewHomepageRecentBlogArticles.sagas');

interface LoadApiResponse {
    body: {
        entities: ReviewHomepageRecentBlogArticlesLoadNormalizedResponse['entities'];
        result: ReviewHomepageRecentBlogArticlesLoadNormalizedResponse['result'];
        hasMore: boolean;
    };
}

function* load({ xhr, articleSlug, after }: ReviewHomepageRecentBlogArticlesLoadTriggerAction & XHRAction) {
    yield put(reviewHomepageRecentBlogArticlesLoadApi.request(articleSlug, after));
    try {
        const response: LoadApiResponse = yield call(
            () => xhr.get(wiki_api_v2_load_recent_blog_articles_url(), { articleSlug, after }),
        );

        yield put(
            reviewHomepageRecentBlogArticlesLoadApi.success(
                articleSlug, after, response.body.hasMore,
                {
                    entities: response.body.entities,
                    result: response.body.result,
                },
            ),
        );
    } catch (error) {
        logger.error(error);
        yield put(reviewHomepageRecentBlogArticlesLoadApi.failure(articleSlug, after, normalizeError(error)));
    }
}

export default function* root() {
    yield all([
        takeEvery(REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_TRIGGER, load),
    ]);
}
