import cx from 'classnames';
import { photoUrl } from 'mk/photo/photoUrl';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { Link } from 'mk2/components/Link';
import { PhotoEntity } from 'mk2/schemas';
import React, { useMemo } from 'react';
import styles from './PhotosRowOfSquares.mscss';

const MARGIN = 5; // right margin of photos
const THUMB_SIZE = 60;
interface OwnProps {
    className?: string;
    photos: PhotoEntity[];
    maxWidth?: number;
    size?: number;
    photoVersion: string;
    linkTo?(photo: PhotoEntity): string;
    galleryLinkTo?(photo: PhotoEntity): string;
}

type Props = OwnProps;

export const PhotosRowOfSquares: React.FunctionComponent<Props> = React.memo(({
    className,
    maxWidth,
    photoVersion,
   /**
    * Size of thumbnails - ideally keep 60px since the correct showing/hiding of thumbs (on mobile screen) is currently
    * based on the check of viewport width and the threshold values are fitted to work with thumbs of size 60 x 60 px.
    */
    // size = 60,
    photos,
    linkTo,
    galleryLinkTo,
}) => {
    // list of photos to display based on maxWidth (on mobile may be more photos hidden via @media styles)
    const photosToDisplay: PhotoEntity[] = useMemo(() => {
        if (!maxWidth) {
            return photos;
        } else {
            let totalWidth = 0;
            totalWidth -= MARGIN; // removing margin of last image
            return photos.reduce<PhotoEntity[]>((acc, cur) => {
                const destinationWidth = THUMB_SIZE + MARGIN;
                if (totalWidth + destinationWidth < maxWidth) {
                    acc.push(cur);
                    totalWidth += destinationWidth;
                    return acc;
                } else {
                    return acc;
                }
            }, []);
        }
    }, [maxWidth, photos]);

    const renderImg = (photo: PhotoEntity) => {
        return (
            <ImgCropped
                className={cx(styles.PhotosRowOfSquares__photo__image, styles['PhotosRowOfSquares__photo--radius'])}
                imgUrl={photoUrl(photo, photoVersion)}
                lazy={false}
                imgWidth={photo.width}
                imgHeight={photo.height}
                mode={ImgCropMode.Cover}
                width={THUMB_SIZE}
                height={THUMB_SIZE}
            />
        );
    };
    const renderMoreCount = (idx: number) => {
        return (
                <div
                    className={cx(
                        styles.PhotosRowOfSquares__photo__more,
                        styles[`PhotosRowOfSquares__photo__more--idx${idx}`],
                        (
                            idx + 1 === photosToDisplay.length &&           // if showing last photo of photosToDisplay
                            photos.length > photosToDisplay.length &&       // and are some more photos to display
                            styles[`PhotosRowOfSquares__photo__more--last`] // then unhide __more (other showing/hiding of _more
                                                                            // is achieved via @media styles)
                        ),
                        styles['PhotosRowOfSquares__photo--radius'],
                    )}
                >
                    {' '}
                    +{photos.length - idx}
                </div>
        );
    };

    return (
        <div
            className={cx(styles.PhotosRowOfSquares, className)}
        >
            {linkTo
                ? photosToDisplay.map((photo: PhotoEntity, idx: number) => {
                    const linkFn = (
                        idx + 1 === photosToDisplay.length &&       // if showing last photo of photosToDisplay
                        photos.length > photosToDisplay.length &&   // and are some more photos to display
                        galleryLinkTo                               // galleryLinkTo is defined
                    ) ? galleryLinkTo : linkTo;
                    return (
                      <Link
                          className={cx(styles.PhotosRowOfSquares__photo, styles[`PhotosRowOfSquares__photo--idx${idx}`])}
                          key={photo.id}
                          to={linkFn(photo)}
                      >
                          {renderImg(photo)}
                          {renderMoreCount(idx)}
                      </Link>
                    );
                  })
                : photosToDisplay.map((photo: PhotoEntity, idx: number) => (
                      <span
                          className={cx(styles.PhotosRowOfSquares__photo, styles[`PhotosRowOfSquares__photo--idx${idx}`])}
                          key={photo.id}
                      >
                          {renderImg(photo)}
                          {renderMoreCount(idx)}
                      </span>
                  ))
            }
        </div>
    );
});

PhotosRowOfSquares.displayName = 'PhotosRowOfSquares';
