import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { Action } from 'redux';

export interface ReviewHomepageRecentBlogArticlesLoadAction extends Action {
    readonly articleSlug: string;
    readonly after: string;
}

export const REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_REQUEST = 'REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_REQUEST';

export interface ReviewHomepageRecentBlogArticlesLoadRequestAction extends ReviewHomepageRecentBlogArticlesLoadAction, APIRequestAction {
    readonly type: typeof REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_REQUEST;
}

export interface ReviewHomepageRecentBlogArticlesLoadNormalizedResponse extends NormalizedResponse {
    readonly result: {
        blogPostArticles: number[];
    };
}

export const REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_SUCCESS = 'REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_SUCCESS';

export interface ReviewHomepageRecentBlogArticlesLoadSuccessAction
    extends ReviewHomepageRecentBlogArticlesLoadAction,
        APISuccessAction<ReviewHomepageRecentBlogArticlesLoadNormalizedResponse> {
    readonly type: typeof REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_SUCCESS;
    readonly hasMore: boolean;
}

export const REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_FAILURE = 'REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_FAILURE';

export interface ReviewHomepageRecentBlogArticlesLoadFailureAction extends ReviewHomepageRecentBlogArticlesLoadAction, APIFailureAction {
    readonly type: typeof REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_FAILURE;
}

export const reviewHomepageRecentBlogArticlesLoadApi = {
    request: (
        articleSlug: string,
        after: string,
    ): ReviewHomepageRecentBlogArticlesLoadRequestAction => ({
        type: REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_REQUEST,
        after,
        articleSlug,
    }),
    success: (
        articleSlug: string,
        after: string,
        hasMore: boolean,
        response: ReviewHomepageRecentBlogArticlesLoadNormalizedResponse,
    ): ReviewHomepageRecentBlogArticlesLoadSuccessAction => ({
        type: REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_SUCCESS,
        articleSlug,
        after,
        hasMore,
        response,
    }),
    failure: (
        articleSlug: string,
        after: string,
        error: any,
    ): ReviewHomepageRecentBlogArticlesLoadFailureAction => ({
        type: REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_FAILURE,
        articleSlug,
        after,
        error,
    }),
};

export const REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_TRIGGER = 'REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_TRIGGER';

export interface ReviewHomepageRecentBlogArticlesLoadTriggerAction extends ReviewHomepageRecentBlogArticlesLoadAction {
    readonly type: typeof REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_TRIGGER;
}

export const reviewHomepageRecentBlogArticlesLoadTrigger = (articleSlug: string, after: string): ReviewHomepageRecentBlogArticlesLoadAction => ({
    type: REVIEW_HOMEPAGE_RECENT_BLOG_ARTICLES_LOAD_TRIGGER,
    articleSlug,
    after,
});
