// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const BACK_TO_HOMEPAGE = "Sp\u00e4\u0165 na hlavn\u00fa str\u00e1nku";
export const PREGNANCY_NEWSLETTER = "Tehotensk\u00fd newsletter";
export const PREGNANCY_NEWSLETTER_DESCRIPTION = "Ka\u017ed\u00fd t\u00fd\u017ede\u0148 dostane\u0161 najd\u00f4le\u017eitej\u0161ie info do e-mailu";
export const RELATED_DISCUSSIONS_IN_FORUM = "S\u00favisiace diskusie vo f\u00f3re";
export const WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_ANY_REVIEWABLE = "M\u00e1\u0161 sk\u00fasenos\u0165 s produktom, slu\u017ebou alebo miestom?";
export const WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_title = "M\u00e1\u0161 sk\u00fasenos\u0165 %(title)s?";
export const WIKI_EXP_AVERAGE_SCORE = "Priemern\u00e9 hodnotenie:";
export const WIKI_EXPERIENCES_WITH_title = "Hodnotenia a sk\u00fasenosti %(title)s";
export const WIKI_SHARE_YOUR_EXPERIENCE_AND_HELP_OTHERS = "Pode\u013e sa o \u0148u a pom\u00f4\u017e tak ostatn\u00fdm mami\u010dk\u00e1m.";
export const WIKI_WHERE_NEXT = "Kam \u010falej?";
export const WIKI_WRITE_YOUR_EXPERIENCE = "Nap\u00ed\u0161 svoju sk\u00fasenos\u0165";
