import {
    initialState,
    perArticleSlugInitialState,
    PerArticleSlugState,
    State,
} from 'mk2/apps/wiki/containers/ReviewHomepageRecentBlogArticles/ReviewHomepageRecentBlogArticles.reducers';
import { AppState } from 'mk2/reducers';

export const getReviewHomepageRecentBlogArticlesState = (state: AppState): State => state.containers.wiki.reviewHomepageRecentBlogArticles || initialState;
export const getReviewHomepageRecentBlogArticlesPerArticleSlugState = (state: State, articleSlug: string): PerArticleSlugState => state[articleSlug] || perArticleSlugInitialState;
