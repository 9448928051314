import { useEffect, RefObject } from 'react';

function useClickOutside<TRef extends HTMLElement>(ref: RefObject<TRef>, callback: () => void): void {
    // Click outside
    useEffect(() => {
        const handler = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, [ref, callback]);
}

export default useClickOutside;
