import {
    count_WIKI_ARTICLE_EXPERIENCES,
    WIKI_REVIEW_CATEGORY__counter_OF_PRODUCT_REVIEWS,
} from 'mk/autogenerated/translations/ReviewCategoryPreview.d8cf1dd0a731e32701a60f9d65ed2703'
import { renderFa } from 'mk2/apps/wiki/components/ArticleBody';
import { Clamp } from 'mk2/components/Clamp';
import { Dot } from 'mk2/components/Dot';
import { Link } from 'mk2/components/Link';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './ReviewCategoryPreview.mscss';

interface OwnProps {
    name: string;
    faIcon: string;
    url: string;
    reviewArticleCount?: number;
    reviewExperiencesCount?: number;
    overridedStats?: React.ReactNode;
}

type Props = OwnProps;

export default class ReviewCategoryPreview extends React.Component<Props> {

    public render() {
        const { faIcon, name, url, reviewArticleCount, reviewExperiencesCount, overridedStats  } = this.props;
        return (
            <div className={styles.ReviewCategoryPreview}>
                <Link to={url}>
                    <div className={styles.ReviewCategoryPreview__content}>
                        <div className={styles.ReviewCategoryPreview__icon}>
                            {renderFa(faIcon)}
                        </div>
                        <div className={styles.ReviewCategoryPreview__rightColumn}>
                            <div className={styles.ReviewCategoryPreview__title}>
                                <h3>
                                    <Clamp clamp={2}>
                                        <div>{name}</div>
                                    </Clamp>
                                </h3>
                            </div>
                            {(reviewArticleCount || reviewExperiencesCount || overridedStats) &&
                                <div className={styles.ReviewCategoryPreview__rightColumn__stats}>
                                    {overridedStats ? (
                                        <>{overridedStats}</>
                                    ) : (
                                        <>
                                            {Boolean(reviewArticleCount) &&
                                                <>{interpolate(WIKI_REVIEW_CATEGORY__counter_OF_PRODUCT_REVIEWS, {
                                                    count: reviewArticleCount,
                                                    counter: reviewArticleCount,
                                                })}</>
                                            }
                                            {Boolean(reviewArticleCount && reviewExperiencesCount) &&
                                                <Dot spaces={1} className={styles.ReviewCategoryPreview__dot} />
                                            }
                                            {Boolean(reviewExperiencesCount) &&
                                                <>{interpolate(count_WIKI_ARTICLE_EXPERIENCES, {count: reviewExperiencesCount})}</>
                                            }
                                        </>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}
