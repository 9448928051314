import cx from 'classnames';
import {
    LIST_OF_RECENT_BLOG_ARTICLES_SHOW_MORE,
    LOAD_ERROR_MESSAGE,
    STROLLEROPEDIA_NEW_ARTICLES,
} from 'mk/autogenerated/translations/ReviewHomepageRecentBlogArticles.07695a34b37112fc3cbf594d088ec954'
import { getArticleTitleAsString } from 'mk2/apps/blogs/containers/Article/Article.helpers';
import { blogsPostDetailUrl } from 'mk2/apps/blogs/urls';
import ReviewBlogPostPreview, {
    ReviewBlogPostPreviewPlaceholder,
} from 'mk2/apps/wiki/components/ReviewBlogPostPreview';
import { reviewHomepageRecentBlogArticlesLoadTrigger } from 'mk2/apps/wiki/containers/ReviewHomepageRecentBlogArticles/ReviewHomepageRecentBlogArticles.actions';
import reducer from 'mk2/apps/wiki/containers/ReviewHomepageRecentBlogArticles/ReviewHomepageRecentBlogArticles.reducers';
import saga from 'mk2/apps/wiki/containers/ReviewHomepageRecentBlogArticles/ReviewHomepageRecentBlogArticles.sagas';
import {
    getReviewHomepageRecentBlogArticlesPerArticleSlugState,
    getReviewHomepageRecentBlogArticlesState,
} from 'mk2/apps/wiki/containers/ReviewHomepageRecentBlogArticles/ReviewHomepageRecentBlogArticles.selectors';
import { getArticleBadges } from 'mk2/components/Badge';
import { Btn, BtnType } from 'mk2/components/Btn';
import { LoadingSwitch } from 'mk2/containers/LoadingSwitch/LoadingSwitch';
import registerInStore from 'mk2/decorators/registerInStore';
import { cacheLast } from 'mk2/helpers/cache';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { url } from 'mk2/helpers/urls';
import { AppState } from 'mk2/reducers';
import { LoadingState } from 'mk2/reducers/loadingState';
import { BlogPostEntity, BlogPostSchema, BlogPostType } from 'mk2/schemas';
import { getDenormalizedEntities } from 'mk2/selectors';
import React from 'react';
import { connect } from 'react-redux';
import styles from './ReviewHomepageRecentBlogArticles.mscss';

interface OwnProps {
    articleSlug: string;
}

interface StateProps {
    blogPostArticles: BlogPostEntity[];
    hasMore: boolean;
    loadingState: LoadingState;
}

interface DispatchProps {
    onLoad(articleSlug: string, after?: string);
}

type Props = OwnProps & StateProps & DispatchProps;

class ReviewHomepageRecentBlogArticles extends React.Component<Props> {

    private articleBadgesCache = cacheLast(true);

    public render() {
        const {loadingState, blogPostArticles} = this.props;
        return (
            <div
                className={cx(
                    styles.ReviewHomepageRecentBlogArticles,
                    blogPostArticles?.length > 0
                        ? styles.ReviewHomepageRecentBlogArticles__show
                        : styles.ReviewHomepageRecentBlogArticles__hide,
                )}
            >
                <h2 className={styles.ReviewHomepageRecentBlogArticles__title}>{STROLLEROPEDIA_NEW_ARTICLES}</h2>
                <LoadingSwitch
                    loadingState={loadingState}
                    onLoad={this.handleOnLoad}
                    onRenderInit={this.renderInitSuccess}
                    onRenderSuccess={this.renderInitSuccess}
                    onRenderFailure={this.renderLoadFailure}
                    hasData={Boolean(blogPostArticles)}
                />
            </div>
        );
    }

    private handleLoadMore = () => {
        const {articleSlug, blogPostArticles, onLoad} = this.props;

        let after = null;
        if (blogPostArticles.length) {
            after = blogPostArticles[blogPostArticles.length - 1].id;
        }
        onLoad(articleSlug, after);
    };

    private handleOnLoad = () => {
        const {articleSlug, onLoad} = this.props;
        onLoad(articleSlug);
    };

    private renderInitSuccess = () => {
        const { blogPostArticles, hasMore, loadingState } = this.props;

        const articleBadges = blogPostArticles ? this.articleBadgesCache(() => {
            const dict = {};
            for (const blogPostArticle of blogPostArticles) {
                dict[blogPostArticle.id] = getArticleBadges(blogPostArticle.hashtagItems).renderedBadges;
            }
            return dict;
        }, [blogPostArticles]) : null;

        return (
            <>
                {blogPostArticles?.map((blogPostArticle, index) => {
                    if (blogPostArticle.type === BlogPostType.ARTICLE) {
                        const author = blogPostArticle.user;
                        const photo = blogPostArticle.photos.length > 0 ? blogPostArticle.photos[0] : undefined;
                        const title = getArticleTitleAsString(blogPostArticle.titleAST);
                        const postUrl = author ? url(blogsPostDetailUrl(blogPostArticle.type), {
                            username: author.username,
                            postSlug: blogPostArticle.slug,
                        }) : null;
                        return (
                            <ReviewBlogPostPreview
                                className={styles.ReviewHomepageRecentBlogArticles__article}
                                key={index}
                                photo={photo}
                                title={title}
                                postUrl={postUrl}
                                badges={articleBadges[blogPostArticle.id]}
                                commentsCount={blogPostArticle.commentsCount}
                                likersCount={blogPostArticle.likeable.likesCount}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
                {loadingState === LoadingState.LOADING &&
                    Array.from(Array(3).keys()).map((index) => (
                        <ReviewBlogPostPreviewPlaceholder
                            key={index}
                            className={styles.ReviewHomepageRecentBlogArticles__article}
                            author={false}
                            badges
                        />
                    ))
                }
                {hasMore && (
                    <Btn
                        label={LIST_OF_RECENT_BLOG_ARTICLES_SHOW_MORE}
                        type={BtnType.Link}
                        className={styles.ReviewHomepageRecentBlogArticles__more}
                        sidePadding={0}
                        onClick={this.handleLoadMore}
                    />
                )}
            </>
        );
    };

    private renderLoadFailure = () => {
        return <div>{LOAD_ERROR_MESSAGE}</div>;
    };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const { articleSlug } = ownProps;
    const reviewHomepageRecentBlogArticlesState = getReviewHomepageRecentBlogArticlesState(state);
    const perArticleSlugState = getReviewHomepageRecentBlogArticlesPerArticleSlugState(reviewHomepageRecentBlogArticlesState, articleSlug);
    const loadingState = perArticleSlugState.loadingState;
    const hasMore = perArticleSlugState.hasMore;
    const blogPostArticles = perArticleSlugState.blogPostArticleIds?.length > 0
        ? getDenormalizedEntities<BlogPostEntity>(state, BlogPostSchema, perArticleSlugState.blogPostArticleIds)
        : null;
    return {
        blogPostArticles,
        hasMore,
        loadingState,
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onLoad: reviewHomepageRecentBlogArticlesLoadTrigger,
};

export default registerInStore<OwnProps>((store) => {
    store.injectReducer('containers.wiki.reviewHomepageRecentBlogArticles', reducer);
    store.injectSaga('containers.wiki.reviewHomepageRecentBlogArticles', saga);
})(connect(mapStateToProps, mapDispatchToProps)(ReviewHomepageRecentBlogArticles));
